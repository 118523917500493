/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const onServiceWorkerUpdateReady = () => {
  // const answer = window.confirm(
  //   `Die Webseite wurde aktualisiert. ` +
  //     `Neu laden um die aktuellste Version anzuzeigen?`
  // )

  // if (answer === true) {
  window.location.reload()
  // }
}

// export const onClientEntry = () => {
//   const Test123 = "this is a test"

//   const storageType = localStorage
//   const consentPropertyName = "hauenstein_consent"

//   const shouldShowPopup = () => !storageType.getItem(consentPropertyName)
//   const saveToStorage = () => storageType.setItem(consentPropertyName, true)

//   if (shouldShowPopup()) {
//     consent = true
//     if (consent) {
//       saveToStorage()
//     }
//   }
// }

// You can delete this file if you're not using it
